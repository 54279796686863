import * as React from "react";
import classNames from "classnames";
import buttonStyles from "../../styles/Button.module.scss";

import styles from './Map.module.scss'
import {BUSINESS_POSITION} from "../../services/constants";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";

const Map = () => {

  const { t, i18n: {language: locale }} = useTranslation();
  const mapRef = useRef(null);
  const infoWindowRef = useRef(null);
  const markerRef = useRef(null);
  const [map, setMap] = useState();
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (mapRef.current && !map) {

      setMap(new window.google.maps.Map(mapRef.current, {
        center: BUSINESS_POSITION,
        zoom: 18,
        mapId: process.env.RAZZLE_PUBLIC_GOOGLE_MAPS_MAP_ID,
        mapTypeId: 'roadmap',
        language: locale,
        controlSize: 24,
      }));
    }
  }, [mapRef, map]);

  useEffect(() => {
    if (map && !marker) {
      setMarker(new google.maps.Marker({
        position: BUSINESS_POSITION,
        map,
        title: t('Tłumacz przysięgły języka ukraińskiego - Michalina Zgódka-Bilous')
      }))
    }
  }, [map, marker, t])

  useEffect(() => {
    if (marker && map) {

      const contentString = `
      <div class="${styles.mapTooltipContainer}">
        <h4>${t('Tłumacz przysięgły języka ukraińskiego')}</h4>
        <h4>${t('Michalina Zgódka-Bilous')}</h4>
        <p>${t('Świderska 102/18')}</p>
        <a href="${process.env.RAZZLE_PUBLIC_GOOGLE_MAPS_BUSINESS_URL}" class="${classNames([
          buttonStyles.Button,
          buttonStyles.buttonPrimary,
        ])}" target="_blank">${t('Zobacz w Mapach Google')}</a>
      </div> 
        `;
      infoWindowRef.current && infoWindowRef.current.close();
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      infoWindowRef.current = infowindow;

      infowindow.open({
        // @ts-ignore
        anchor: marker,
        map,
        shouldFocus: false,
      });

      marker.addListener("click", () => {
        infowindow.open({
          // @ts-ignore
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });
    }
  }, [marker, map])

  return (
      <div ref={mapRef} className={styles.mapContainer} />
  );
};

export default Map;
