import * as React from "react";
import styles from "./Map.module.scss";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import Map from './Map';
import Marker from './Marker';
import {BUSINESS_POSITION} from "../../services/constants";

const BusinessMap = () => {

  const backupEmbededMap = () => (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d304.86443886840203!2d20.950121145990554!3d52.31753560565084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ec921e1355ce7%3A0xfce3a9604228c4e5!2zVMWCdW1hY3ogcHJ6eXNpxJlnxYJ5IGrEmXp5a2EgdWtyYWnFhHNraWVnbyBNaWNoYWxpbmEgWmfDs2RrYS1CaWxvdXM!5e0!3m2!1spl!2spl!4v1651046146564!5m2!1spl!2spl"
      width="600" height="450"
      className={styles.mapIframe}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"></iframe>
  )

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div>Loading...</div>;
      case Status.FAILURE:
        return backupEmbededMap();
      case Status.SUCCESS:
        return (
          <Map>
            <Marker position={BUSINESS_POSITION} />
          </Map>
        );
    }
  };

  return (
    <div className={styles.mapContainer}>
      <Wrapper
        apiKey={process.env.RAZZLE_PUBLIC_GOOGLE_MAPS_API_KEY || ''}
        render={render}
      />
    </div>
  );
};

export default BusinessMap;
